import screenfull from 'screenfull'
export const mixinTableList = {
  data () {
    return {
      screenfull,
      tableData: [],
      tableOtherData: null,
      tableTdStyle: 'default',
      rowKey: 'key',
      isSelectedAll: false,
      selectedRowKeys: [],
      isFullscreen: false,
      expand: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        filters: null,
        sorter: null
      },
      filters: null,
      sorter: null,
      loading: false,
    }
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  methods: {
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (JSON.stringify(filters) === '{}') {
        this.filters = null
      } else {
        this.filters = filters
      }
      if (JSON.stringify(sorter) === '{}') {
        this.sorter = null
      } else {
        this.sorter = sorter
      }
      this.fetch()
    },
    async fetch () {
      if (this.loading) return;
      this.loading = true
      const listRes = await this.$axios.post(this.tableListApi, {
        results: this.pagination.pageSize,
        page: this.pagination.current,
        searchParams: this.searchParams,
        filters: this.filters,
        sorter: this.sorter
      }).catch(e => {
        this.loading = false
        return { status: false, message: e.message.indexOf('401') > -1 ? '登录超时' : e.message }
      })
      this.loading = false
      if (!listRes.status) {
        this.$notification.error({ message: '获取数据错误', description: listRes.message })
        return
      }
      const { rows, count, ...other} = listRes.data
      this.tableOtherData = other
      if (typeof this.getTableReset === 'function') {
        const data = this.getTableReset(rows)
        this.$set(this, 'tableData', data)
      } else {
        this.$set(this, 'tableData', rows)
      }
      this.$set(this.pagination, 'total', count)
      if (this.isSelectedAll) {
        const selectedRowKeys = this.selectedRowKeys
        for (const it of listRes.data.rows) {
          if (it[this.rowKey] && !selectedRowKeys.includes(it[this.rowKey])) {
            selectedRowKeys.push(it[this.rowKey])
          }
        }
      }
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.isSelectedAll = false;
    },
    async onSelectAll(selected) {
      this.isSelectedAll = selected
    }
  }
}

export const aliveVM = {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$root.$data.viewsShow = vm
    })
  },
  beforeRouteLeave (to, from, next) {
    this.$root.$data.viewsShow = null
    next()
  },
  beforeDestroy () {
    this.$root.$data.viewsShow = null
  },
  methods: {
    async reload () { }
  },
}